import React from 'react';
import { ListCardExchanges } from '@/components/Exchange';
import { useGetExchangeQuery } from '@/generated/graphql';
import Link from 'next/link';
import { ArrowRightIcon, StarBadgeIcon, TickBadgeIcon } from '@/assets/icons';
import {
  ExchangeExpertHomepageParams,
  ExchangeUserHomepageParams,
} from '@/constants/defaultParams';
import { UrlLink } from '@/configs/urlLink';

export default function ExchangeSection() {
  const { loading: loadingExchangeExpert, data: dataExchangeExpert } =
    useGetExchangeQuery({
      variables: ExchangeExpertHomepageParams,
    });

  const { loading: loadingExchangeUser, data: dataExchangeUser } =
    useGetExchangeQuery({
      variables: ExchangeUserHomepageParams,
    });

  return (
    <div className="container">
      <Link
        className="border-neutral-grayLight hover:text-primary-eastern flex items-center gap-5 border-b-[0.5px] pb-2 lg:border-b-[0px]"
        href={UrlLink.broker.index}
      >
        <h2 className="font-header text-2xl font-semibold">Top sàn môi giới</h2>
        <ArrowRightIcon />
      </Link>
      <div>
        <div className="flex flex-col gap-4 px-0 py-6">
          <div className="flex items-center gap-2 ">
            <TickBadgeIcon />
            <p className="text-neutral-background-dark lg:text-PC-Title-Large font-sans text-lg font-semibold">
              Chuyên gia khuyến nghị
            </p>
          </div>
          <div className="flex w-full gap-y-8">
            <ListCardExchanges
              listCards={dataExchangeExpert?.exchange || []}
              isLoading={loadingExchangeExpert}
              type="expert"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 px-0 py-6">
          <div className="flex items-center gap-2 ">
            <StarBadgeIcon />
            <p className="text-neutral-background-dark lg:text-PC-Title-Large font-sans text-lg font-semibold">
              Nhà đầu tư tin chọn
            </p>
          </div>
          <div className="flex w-full gap-y-8">
            <ListCardExchanges
              listCards={dataExchangeUser?.exchange || []}
              isLoading={loadingExchangeUser}
              type="user"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
